import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private openSideBar: boolean = false;

  private subject: Subject<boolean> = new Subject();
  constructor() { }

  toggleOpenSideBar(): void {
    this.openSideBar = !this.openSideBar;
    this.subject.next(this.openSideBar);
  }

  onToggleOpenSideBar(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
