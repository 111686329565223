import { UiService } from './../../services/ui.service';
import { Component } from '@angular/core';
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { faBraille, faCog, faNewspaper, faThLarge, faUser } from "@fortawesome/free-solid-svg-icons";
import { Subscription, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { ApplicationConfigurationDto, AuthService, ConfigStateService } from '@abp/ng.core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent{
  isAuthenticated$: Observable<boolean> = of(false);
  isAuthenticated: boolean = false;
  faCog = faCog;
  faThLarge = faThLarge;
  faUser = faUser;
  faNewspaper = faNewspaper;
  faBraille = faBraille;
  faDotCircle = faDotCircle;
  sidebarOpen = false;
  userOpen = false;

  constructor(private uiService: UiService, private config: ConfigStateService, private auth: AuthService, private router: Router) {
    this.uiService.onToggleOpenSideBar().subscribe(isopen => {
      this.sidebarOpen = isopen;
    });
    this.isAuthenticated = this.config.getAll().currentUser.isAuthenticated;
  }
  get email(): string {
    return this.config.getAll().currentUser.email??'-';
  }

  get name(): string {
    return this.config.getAll().currentUser.name??'-';
  }

  get role(): string {
    return this.config.getAll().currentUser.roles[0];
  }

  get tenantId(): string|undefined {
    return this.config.getAll().currentUser.tenantId;
  }

  signOut() { this.auth.logout().subscribe(a=>{
    console.log(a);
  }); }

  profile() {
    this.router.navigate(['profile']);
  }

  changePassword() {
    this.router.navigate(['profile', 'change-password']);
  }

  setting() {
    this.router.navigate(['profile', 'setting']);
  }

  materialManager() {
    this.router.navigate(['material-manager', 'list']);
  }

  layout() {
    this.router.navigate(['layout', 'list']);
  }

  content() {
    this.router.navigate(['content', 'list']);
  }

  user() {
    this.router.navigate(['user', 'list']);
  }

  tenant() {
    this.router.navigate(['tenant', 'list']);
  }

  program() {
    this.router.navigate(['program', 'list']);
  }

}
