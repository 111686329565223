import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  canActivate() {
    if(this.config.getAll().currentTenant.id === null ){
      this.router.navigate(['user/list']);
    }
    return true;
  }
  //Constructor
  constructor(private router: Router,private config: ConfigStateService, private authService: AuthService) { }
}
