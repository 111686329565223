import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  sidebarOpen = false;
  constructor(private uiService: UiService) { }

  ngOnInit(): void {
  }

  openSideBar() {
    this.uiService.toggleOpenSideBar();
  }

}
