import { Environment } from '@abp/ng.core';

const baseUrl = 'http://localhost:4200';

export const environment = {
  environment: 'local',
  stillFileSizeLimit: {"size":20485760, "text": "20MB"},
  production: false,
  application: {
    baseUrl,
    name: 'ContentCreator',
    logoUrl: baseUrl,
  },
  oAuthConfig: {
    issuer: 'https://localhost:44347',
    redirectUri: baseUrl,
    clientId: 'ContentCreator_App',
    responseType: 'code',
    scope: 'offline_access ContentCreator',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://localhost:44334',
      rootNamespace: 'ContentCreator',
    },
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
