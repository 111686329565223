<div class="flex h-screen bg-gray-50 font-roboto">
  <app-menu class="flex h-screen"></app-menu>
  <div class="flex-1 flex flex-col overflow-hidden">

    <app-header class="flex justify-between items-center px-6"></app-header>

    <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50">
      <div class="container mx-auto px-6 py-8">

        <router-outlet></router-outlet>

      </div>
    </main>
    <app-footer></app-footer>
  </div>
</div>
