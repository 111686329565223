<div *ngIf="isAuthenticated" [class.block]="sidebarOpen" [class.hidden]="!sidebarOpen" (click)="sidebarOpen = false"
  class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>

<div *ngIf="isAuthenticated" [ngClass]="{'translate-x-0 ease-out': sidebarOpen, '-translate-x-full ease-in': !sidebarOpen}"
  class="fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-white overflow-y-auto lg:translate-x-0 lg:static lg:inset-0">
  <div class="flex items-center justify-center mt-8">
    <div class="flex items-center">
      <fa-icon [icon]="faDotCircle" class="text-purple-500 mr-1 text-2xl"></fa-icon>
      <span class="text-gray-800 text-2xl mx-2 font-semibold">Contents Creator</span>
    </div>
  </div>

  <!-- <div class="mt-6"> -->

  <div *ngIf="isAuthenticated" class="w-full p-4 pb-0">
    <div class="flex items-center justify-between p-2 cursor-pointer hover:bg-gray-100 rounded-lg" (click)="userOpen = !userOpen">

      <div class="ml-2">
        <p class="font-medium">{{ name }}</p>
        <p class="text-gray-400 text-sm">{{ email }}</p>
      </div>
      <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
      </svg>
    </div>
    <ul *ngIf="userOpen" class="p-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50">
      <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800">
        <a class="inline-flex items-center w-full" (click)="profile()">
          <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
          </svg>
          <span>{{ 'Text.UserManage.Profile'|translate }}</span>
        </a>
      </li>
      <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800">
        <a class="inline-flex items-center w-full" (click)="changePassword()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 mr-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
          </svg>
          <span>{{ 'Text.UserManage.resetPassword'|translate }}</span>
        </a>
      </li>

      <!-- <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800">
        <a class="inline-flex items-center w-full" (click)="setting()">
          <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
            </path>
            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
          </svg>
          <span>{{ 'Text.UserManage.UserSetting'|translate }}</span>
        </a>
      </li> -->

      <li class="px-2 py-1 transition-colors duration-150 hover:text-gray-800">
        <a class="inline-flex items-center w-full" (click)="signOut()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>
          <span>{{ 'Text.Logout'|translate }}</span>
        </a>
      </li>

    </ul>
  </div>
  <!-- </div> -->
  <ul *ngIf="isAuthenticated" class="mt-6">
    <ng-container *ngIf="tenantId!==null">

    <li class="relative pl-4 py-3" *abpPermission="'ContentCreator.Layouts'">
      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="layout()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
        </svg>
        <span class="mx-3">{{ 'Text.Layout'|translate }}</span>
      </a>
    </li>
    <li class="relative pl-4 py-3" *abpPermission="'ContentCreator.Layouts'">
      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="content()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
        <span class="mx-3">{{ 'Text.Content'|translate }}</span>
      </a>
    </li>
    <li class="relative pl-4 py-3" *abpPermission="'ContentCreator.Materials'">

      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="materialManager()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
        </svg>
        <span class="mx-3">{{ 'Text.MaterialManager'|translate }}</span>
      </a>
    </li>
  </ng-container>
    <!-- <li class="relative pl-4 py-3">

      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="program()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
        </svg>
        <span class="mx-3">Program</span>
      </a>
    </li> -->
    <li class="relative pl-4 py-3" *abpPermission="'AbpTenantManagement.Tenants'">
      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="tenant()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
        </svg>
        <span class="mx-3">{{ 'Text.TenantManager'|translate }}</span>
      </a>
    </li>
    <li class="relative pl-4 py-3" *abpPermission="'AbpIdentity.Users'">
      <a href="javascript:;" routerLinkActive="menu-current" class="menu-link" (click)="user()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
        <span class="mx-3">{{ 'Text.UserManager'|translate }}</span>
      </a>
    </li>

    <!-- <li class="relative pl-4 py-3">
      <a href="#" routerLinkActive="menu-current" class="menu-link">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
        </svg>
        <span class="mx-3">{{ 'Text.Setting'|translate }}</span>
      </a>
    </li> -->
  </ul>

</div>
