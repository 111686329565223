import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



(async () => {
  const response = await fetch('assets/app-config.json');
  const config = await response.json();

  Object.assign(environment, config);

  if (environment.environment!=='local') {
    enableProdMode();
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

})();
