import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  faDotCircle = faDotCircle;

  constructor(private authService: OAuthService) { }

  ngOnInit(): void {
  }

  public signIn($event: any) {
    $event.preventDefault();
    this.authService.initLoginFlow();
  }

}
