import { AuthGuard } from '@abp/ng.core';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './services/admin-guard.service';
import { TenantGuard } from './services/tenant-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/layout/list', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'tenant', loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule), canActivate: [AuthGuard, TenantGuard] },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard] },
  { path: 'content', loadChildren: () => import('./content/content.module').then(m => m.ContentModule), canActivate: [AuthGuard, AdminGuard] },
  { path: 'layout', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard, AdminGuard]},
  { path: 'user', loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
  { path: 'material-manager', loadChildren: () => import('./material-manager/material-manager.module').then(m => m.MaterialManagerModule), canActivate: [AuthGuard, AdminGuard] },
  { path: 'program', loadChildren: () => import('./program/program.module').then(m => m.ProgramModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/layout/list' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
