import { Component, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  lang: string;
  /**
   *
   */
  constructor(
    public viewContainerRef: ViewContainerRef
    , private translate: TranslateService
    ) {
    translate.addLangs(["ja-JP","zh-TW"]);
    if (/^zh-tw\b/.test(navigator.language)) {
      this.lang = 'zh-TW';
    } else if (/^ja\b/.test(navigator.language)) {
      this.lang = 'ja-JP';
    } else {
      this.lang = translate.getBrowserCultureLang()!;
    }
    this.translate.use(this.lang);

  }
  title = 'ContentCreator';
}
